import { getAddCartTrackParam } from '~/utils'

const trackMap = {
  // 默认没有来源-加购支付
  default: {
    add_cart_event: 't_default_add_cart',
    add_order_event: 't_default_add_order',
    track: '/DEFAULT/ADDCART'
  },
  // 总的首页-加购支付
  index: {
    page: 'index',
    add_cart_event: 't_index_add_cart',
    add_order_event: 't_index_add_order',
    track: '/INDEX/ADDCART'
  },
  // 总的首页-分类-加购支付
  indexCategory: {
    page: 'index',
    add_cart_event: 't_index_category_add_cart',
    add_order_event: 't_index_category_add_order',
    track: '/INDEX/CATEGORY/ADDCART'
  },
  // 总的首页-搜索-加购支付
  indexSearch : {
    module: 'header_search_input',
    add_cart_event: 't_index_search_add_cart',
    add_order_event: 't_index_search_add_order',
    track: '/INDEX/SEARCH/ADDCART'
  },
  // 总的banner-加购支付
  totalBanner: {
    module: 'home_banner',
    add_cart_event: 't_banner_add_cart',
    add_order_event: 't_banner_add_order',
    track: '/BANNER/ADDCART'
  },
  // 搜索-加购支付
  search: {
    page: 'search',
    add_cart_event: 't_search_add_cart',
    add_order_event: 't_search_add_order',
    track: '/SEARCH/ADDCART'
  },
  // 首页banner-加购支付
  banner: {
    module: 'home_banner',
    add_cart_event: 't_banner_product_add_cart',
    add_order_event: 't_banner_product_add_order',
    track: '/BANNER/PRODUCT/ADDCART'
  },
  // 首页newArrivals模块-加购支付
  newArrivals: {
    module: 'home_new_arrivals',
    add_cart_event: 't_new_product_add_cart',
    add_order_event: 't_new_product_add_order',
    track: '/NEW/ADDCART'
  },
  // 浏览记录-加购支付
  history: {
    page:'my-account-browsing-history',
    add_cart_event: 't_history_product_add_cart',
    add_order_event: 't_history_product_add_order',
    track: '/HISTORY/ADDCART'
  },
  // 活动专题-加购支付
  themeActivity: {
    page: 'activity-theme-id',
    add_cart_event: 't_theme_activity_add_cart',
    add_order_event: 't_theme_activity_add_order',
    track: '/THEMEACTIVITY/ADDCART'
  },
  // 分类页-加购支付
  category: {
    page: 'category-id',
    add_cart_event: 't_category_product_add_cart',
    add_order_event: 't_category_product_add_order',
    track: '/CATEGORY/ADDCART'
  },
  // banner-分类页-加购支付
  bannerCategory: {
    add_cart_event: 't_banner_category_product_add_cart',
    add_order_event: 't_banner_category_product_add_order',
    track: '/BANNER/CATEGORY/ADDCART'
  },
  // banner-活动专题-加购支付
  bannerThemeActivity: {
    add_cart_event: 't_banner_theme_activity_add_cart',
    add_order_event: 't_banner_theme_activity_add_order',
    track: '/BANNER/THEMEACTIVITY/ADDCART'
  },
  // products里的推荐产品图片-加购支付
  navProducts: {
    module: 'header_nav_products_menu_img',
    add_cart_event: 't_products_add_cart',
    add_order_event: 't_products_add_order',
    track: '/PRODUCTS/ADDCART'
  },
  // products里的分类菜单-分类页-加购支付
  navProductsCategory: {
    module: 'header_nav_products_menu',
    add_cart_event: 't_products_category_add_cart',
    add_order_event: 't_products_category_add_order',
    track: '/PRODUCTS/CATEGORY/ADDCART'
  },
  // 导航newArrivals-加购支付
  navNewArrivals: {
    page: 'new-arrivals',
    add_cart_event: 't_new_arrivals_add_cart',
    add_order_event: 't_new_arrivals_add_order',
    track: '/NEW_ARRIVALS/ADDCART'
  },
  // 导航brands-加购支付
  navBrands: {
    page: 'brands',
    add_cart_event: 't_brand_product_add_cart',
    add_order_event: 't_brand_product_add_order',
    track: '/BRANDS/ADDCART'
  },
  // 导航featured-加购支付
  navFeatured: {
    page: 'featured-products',
    add_cart_event: 't_featured_product_add_cart',
    add_order_event: 't_featured_product_add_order',
    track: '/FEATURED/ADDCART'
  },
  // topSellingCategories-加购支付
  topSellingCategories: {
    module: 'home_top_selling_categories',
    add_cart_event: 't_top_selling_categories_add_cart',
    add_order_event: 't_top_selling_categories_add_order',
    track: '/TOP_SELLING_CATEGORIES/ADDCART'
  },
  // recommendTop-加购支付
  recommendTop: {
    module: 'home_hv_products_top',
    add_cart_event: 't_recommend_top_add_cart',
    add_order_event: 't_recommend_top_add_order',
    track: '/RECOMMEND_TOP/ADDCART'
  },
  // recommendBottom-加购支付
  recommendBottom: {
    module: 'home_hv_products_bottom',
    add_cart_event: 't_recommend_bottom_add_cart',
    add_order_event: 't_recommend_bottom_add_order',
    track: '/RECOMMEND_BOTTOM/ADDCART'
  }
}

// 加购链路处理
export const trackAddCart = (product) => {
  const { $Reporter } = useNuxtApp()
  const productCode = product?.code || ''
  const tracks = $Reporter.getTrackList()
  const route = useRoute()
  const t1 = tracks[0] || {}
  const t2 = tracks[1] || {}
  const list = []

  // 来自搜索 或 来自搜索框直达的
  if (t1.pageName === trackMap.search.page || route.query.source === 'header_search') {
    const keyword = t1.query.keyword || route.query.k || ''
    $Reporter.track({ productCode, keyword, event: trackMap.search.add_cart_event })
    list.push(`${trackMap.search.track}/${keyword}`)
    
    // 来自首页搜索
    if ((route.query.source === 'header_search' && t1.pageName === trackMap.index.page) || t2.pageName === trackMap.index.page) {
      $Reporter.track({ productCode, keyword, event: trackMap.indexSearch.add_cart_event })
      list.push(`${trackMap.indexSearch.track}/${keyword}`)
    }
    return list
  }

  // 来自首页
  if (t1.pageName === trackMap.index.page) {
    // 来自首页(总)
    $Reporter.track({ productCode, event: trackMap.index.add_cart_event })
    list.push(trackMap.index.track)

    // 来自首页banner
    if(t1.moduleName === trackMap.banner.module) {
      $Reporter.track({ productCode, event: trackMap.banner.add_cart_event })
      $Reporter.track({ productCode, event: trackMap.totalBanner.add_cart_event })
      list.push(trackMap.banner.track)
      list.push(trackMap.totalBanner.track)
    }

    // 来自new Arrivals产品
    if(t1.moduleName === trackMap.newArrivals.module) {
      $Reporter.track({ productCode, event: trackMap.newArrivals.add_cart_event })
      list.push(trackMap.newArrivals.track)
    }

    // 来自recommendTop
    if(t1.moduleName === trackMap.recommendTop.module) {
      $Reporter.track({ productCode, event: trackMap.recommendTop.add_cart_event })
      list.push(trackMap.recommendTop.track)
    }

    // 来自recommendBottom
    if(t1.moduleName === trackMap.recommendBottom.module) {
      $Reporter.track({ productCode, event: trackMap.recommendBottom.add_cart_event })
      list.push(trackMap.recommendBottom.track)
    }
  }

  // 来自浏览记录
  if (t1.pageName === trackMap.history.page) {
    $Reporter.track({ productCode, event: trackMap.history.add_cart_event })
    list.push(trackMap.history.track)
  }

  // 来自活动专题
  if (t1.pageName === trackMap.themeActivity.page) {
    const theme_activity_id = t1.params.id
    
    // 来自banner
    if (t2.moduleName === trackMap.banner.module) {
      $Reporter.track({ productCode, theme_activity_id, event: trackMap.bannerThemeActivity.add_cart_event })
      $Reporter.track({ productCode, event: trackMap.totalBanner.add_cart_event })
      list.push(`${trackMap.totalBanner.track}`)
      list.push(`${trackMap.bannerThemeActivity.track}/${theme_activity_id}`)
    } else {
      $Reporter.track({ productCode, theme_activity_id, event: trackMap.themeActivity.add_cart_event })
      list.push(`${trackMap.themeActivity.track}/${theme_activity_id}`)
    }
  }

  // 来自分类页
  if (t1.pageName === trackMap.category.page) {
    const category_id = t1.params.id
    $Reporter.track({ productCode, category_id, event: trackMap.category.add_cart_event })
    list.push(`${trackMap.category.track}/${category_id}`)

    // 来自banner
    if (t2.moduleName === trackMap.banner.module) {
      $Reporter.track({ productCode, category_id, event: trackMap.bannerCategory.add_cart_event })
      $Reporter.track({ productCode, event: trackMap.totalBanner.add_cart_event })
      list.push(`${trackMap.totalBanner.track}`)
      list.push(`${trackMap.bannerCategory.track}/${category_id}`)
    }

    // 来自productsCategory
    if(t2.moduleName === trackMap.navProductsCategory.module) {
      $Reporter.track({ productCode, category_id, event: trackMap.navProductsCategory.add_cart_event })
      list.push(`${trackMap.navProductsCategory.track}/${category_id}`)
    }

    // 来自topSellingCategories
    if(t2.moduleName === trackMap.topSellingCategories.module) {
      $Reporter.track({ productCode, category_id, event: trackMap.topSellingCategories.add_cart_event })
      list.push(`${trackMap.topSellingCategories.track}/${category_id}`)
    }
    
    // 来自首页(总)
    if(t2.pageName === trackMap.indexCategory.page) {
      $Reporter.track({ productCode, category_id, event: trackMap.indexCategory.add_cart_event })
      list.push(`${trackMap.indexCategory.track}/${category_id}`)
    }
  }

  // 来自products里的推荐产品图片
  if (t1.moduleName === trackMap.navProducts.module) {
    $Reporter.track({ productCode, event: trackMap.navProducts.add_cart_event, recommend_id: route.query.products_recommend_id })
    list.push(trackMap.navProducts.track)

    return list
  }

  // 来自导航newArrivals
  if (t1.pageName === trackMap.navNewArrivals.page) {
    $Reporter.track({ productCode, event: trackMap.navNewArrivals.add_cart_event })
    list.push(trackMap.navNewArrivals.track)
  }

  // 来自导航brands
  if (t1.pageName === trackMap.navBrands.page) {
    $Reporter.track({ productCode, event: trackMap.navBrands.add_cart_event })
    list.push(trackMap.navBrands.track)
  }

  // 来自导航featured
  if (t1.pageName === trackMap.navFeatured.page) {
    $Reporter.track({ productCode, featured_id: t1.query.queryType, event: trackMap.navFeatured.add_cart_event })
    list.push(`${trackMap.navFeatured.track}/${t1.query.queryType}`)
  }

  // 未知来源
  if (list.length === 0) {
    $Reporter.track({ productCode, event: trackMap.default.add_cart_event })
    list.push(trackMap.default.track)
  }
  
  return list
}

// 下单链路处理
export const trackAddOrder = (cartList, orderDetail)  => {
  const { $Reporter } = useNuxtApp()
  for (let i = 0, len = cartList.length; i < len; i++) {
    const product = cartList[i];
    const optSources = product?.optSource ? [{optSource: product?.optSource}] : (product?.optSources || [])
    for (let j = 0; j < optSources.length; j++) {
      const source = optSources[j].optSource;
      for (let k in trackMap){
        if (source.startsWith(trackMap[k]?.track)) {
          $Reporter.track({
            event: trackMap[k]?.add_order_event,
            productCode: product?.productInfo?.code,
            order_code: orderDetail?.code,
            source_business_id: getAddCartTrackParam(trackMap[k]?.track)
          })
        }
      }
    }
  }
}

// 任何来源到首页、分类页、详情页
const anyFromToPageMap = [
  { page: 'index', event: 't_any_to_index' },
  { page: 'category-id', event: 't_any_to_category' },
  { page: 'product-id', event: 't_any_to_product' }
]
// 来自于某某页面的事件处理
export const trackFromPage = () => {
  const { $Reporter } = useNuxtApp()
  const route = useRoute()
  const currentPageName = route.name
  const tracks = $Reporter.getTrackList()
  // console.log('Tracks ----->', tracks)

  // 最近的一个track来源
  const t1 = tracks[0] || {}
  const t2 = tracks[1] || {}
  // 来源
  const source = t1?.pageName || t1?.source || ''
  // 1:站内 2:站外
  const type = t1?.type || 2

  const lastPageName = t1.pageName
  const lastModuleName = t1.moduleName

  // 任何来源到首页、分类页、详情页
  anyFromToPageMap.forEach(item => {
    if (item.page === currentPageName) {
      $Reporter.track({ event: item.event, source, type })
    }
  })

  // 到分类页
  if (currentPageName === 'category-id') {
    // 首页到分类
    if (lastPageName === 'index') {
      $Reporter.track({ event: 't_home_to_category' })
    }

    //products推荐分类-分类
    if (lastModuleName === trackMap.navProductsCategory.module) {
      $Reporter.track({ event: 't_nav_products_to_category' })
    }

    // 来自topSellingCategories
    if (lastModuleName === trackMap.topSellingCategories.module) {
      $Reporter.track({ event: 't_top_selling_to_list' })
    }

    // 首页banner到分类
    if (lastModuleName === trackMap.banner.module) {
      $Reporter.track({ event: 't_banner_to_category' })
    }
    return
  }

  // 到专题活动
  if (currentPageName === 'activity-theme-id') {
    if (lastModuleName === trackMap.banner.module) {
      $Reporter.track({ event: 't_banner_to_theme_activity', theme_activity_id: route.params?.id })
    }
    return
  }

  // 到new-arrivals页
  if (currentPageName === 'new-arrivals') {
    $Reporter.track({ event: 't_nav_new_arrivals_to_list' })
    return
  }

  // 到品牌页
  if (currentPageName === 'brands') {
    $Reporter.track({ event: 't_nav_brands_to_list' })
    return
  }

  // 来自featured页
  if (currentPageName === 'featured-products') {
    $Reporter.track({ event: 't_nav_featured_to_list' })
    return
  }
  
  // 到详情页
  if (currentPageName === 'product-id') {
    const productCode = parseInt(route.params?.id) || ''

    // products推荐图片-详情
    if (lastModuleName === trackMap.navProducts.module) {
      $Reporter.track({ 
        event: 't_products_to_product',
        productCode,
      })
      return
    }

    // 来自搜索 || 顶部header搜索框搜索直接到详情页
    if (route.query.source === 'header_search') {
      const keyword = t1.query.keyword || route.query.k || ''
      $Reporter.track({ productCode, keyword, event: 't_search_to_product' })

      // 首页搜索到详情
      if (route.query.source === 'header_search' && t1.pageName === 'index') {
        $Reporter.track({ productCode, keyword, event: 't_index_search_to_product' })
      }

      return
    }

    // 导航new_arrivals到列表到详情
    if (lastPageName === 'new-arrivals') {
      $Reporter.track({
        productCode,
        event: 't_nav_new_arrivals_to_list_to_product'
      })
      return
    }

    // 品牌到列表到详情
    if (lastPageName === 'brands') {
      $Reporter.track({
        productCode,
        event: 't_nav_brands_to_list_to_product'
      })
      return
    }

    // featured到列表到详情
    if (lastPageName === 'featured-products') {
      $Reporter.track({
        productCode,
        featured_id: t1.query.queryType,
        event: 't_nav_featured_to_list_to_product'
      })
      return
    }

    // 来自首页
    if (lastPageName === 'index') {
      $Reporter.track({ event: 't_home_to_product', productCode })
      // 来自首页banner
      if (lastModuleName === 'home_banner') {
        $Reporter.track({ event:'t_banner_to_product', productCode })
        $Reporter.track({ event:'t_banner_product', productCode })
      }

      // 来自new Arrivals
      if (lastModuleName === 'home_new_arrivals') {
        $Reporter.track({ event:'t_new_arrivals_to_product', productCode })
      }

      // 推荐商品top到详情
      if (lastModuleName === trackMap.recommendTop.module) {
        $Reporter.track({
          productCode,
          event: 't_recommend_top_to_product'
        })
      }

      // 推荐商品bottom到详情
      if (lastModuleName === trackMap.recommendBottom.module) {
        $Reporter.track({
          productCode,
          event: 't_recommend_bottom_to_product'
        })
      }

      return
    }

    // 来自历史记录
    if (lastPageName ==='my-account-browsing-history') {
      $Reporter.track({
        productCode,
        event: 't_history_to_product'
      })
      return
    }
    
    // 来自专题活动
    if (lastPageName === 'activity-theme-id') {
      // 来自banner到专题页到详情页
      if (t2.moduleName === 'home_banner') {
        $Reporter.track({
          productCode,
          theme_activity_id: t1.params.id,
          event: 't_banner_theme_activity_to_product'
        })
        $Reporter.track({ event:'t_banner_product', productCode })
      } else {
        $Reporter.track({
          productCode,
          theme_activity_id: t1.params.id,
          event: 't_theme_activity_to_product',
        })
      }
      return
    }

    // 来自分类页
    if (lastPageName === 'category-id') {
      const category_id = t1.params.id || ''
      $Reporter.track({
        productCode,
        category_id,
        event: 't_category_to_product'
      })
      // 来自banner到分类页到详情页
      if (t2.moduleName === 'home_banner') {
        $Reporter.track({
          productCode,
          category_id,
          event: 't_banner_category_to_product'
        })
        $Reporter.track({ event:'t_banner_product', productCode })
      }
      // products推荐分类-分类-详情
      if (t2.moduleName === trackMap.navProductsCategory.module) {
        $Reporter.track({ 
          productCode,
          category_id,
          event: 't_nav_products_to_category_to_product',
        })
      }

      // top_selling到列表到详情
      if (t2.moduleName === trackMap.topSellingCategories.module) {
        $Reporter.track({
          productCode,
          category_id,
          event: 't_top_selling_to_list_to_product'
        })
      }

      // 首页分类到详情
      if (t2.pageName === 'index') {
        $Reporter.track({
          productCode,
          category_id,
          event: 't_home_to_category_to_product'
        })
      }

      return
    }
  }
}